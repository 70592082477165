import React from 'react';
import spacetime from 'spacetime';
import ReactMarkdown from 'react-markdown';

// scss
import './message.styles.scss'

// components
import Card from '../chatbot/card/card.component';
import QuickReply from '../chatbot/quick-reply/quick-reply.component';



const Message = ({ speaker, text, cards, chips, handleQuickReply, cardStyle, qRStyle, scriptStyle, lastMessageInSequence }) => (

      <>
        {speaker !== 'user' ?
          <div className="wrapper">

            {!cardStyle && !qRStyle && !scriptStyle ?
                <div className="avatar-section">
                    <div className="avatar">
                        <svg width="95%" height="95%" viewBox="0 0 24 24" className="Twilio-Icon-Content"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.2"><circle cx="12" cy="7.896" r="5.26"></circle><path d="M9.662 9.638c.778.78 1.557 1.17 2.338 1.17.782 0 1.564-.39 2.348-1.17M4.499 21.337c.04-2.067.774-3.756 2.199-5.067C8.123 14.958 9.89 14.31 12 14.325M19.501 21.338c-.04-2.067-.774-3.757-2.199-5.068-1.425-1.312-3.192-1.96-5.302-1.945" strokeLinecap="round" strokeLinejoin="round"></path></g></svg>
                    </div>
                </div>

                :
                null
            }

            <div className={cardStyle ? "message-container-bot-cards" : "message-container-bot" && qRStyle ? "message-container-bot-quick-replies" : "message-container-bot" && scriptStyle ? "message-container-script" : "message-container-bot"}>
                
                {!cardStyle && !qRStyle && !scriptStyle ?
                    <div className="bot-header">
                        <p><strong>{speaker}</strong></p>
                        <p>{spacetime.now().time()}</p>
                    </div>
                    :
                    null
                }

                {
                    text &&
                    <div className="message-content">
                        <ReactMarkdown source={text} />
                    </div>
                }
                {
                    cards &&
                    <>
                        <div className="cards-container">
                            {
                                cards.map((card, i) => {
                                    return <Card
                                        key={i}
                                        header={card.structValue.fields.header.stringValue}
                                        link={card.structValue.fields.link.stringValue}
                                        price={card.structValue.fields.price.stringValue}
                                        image={card.structValue.fields.image.stringValue}
                                        description={card.structValue.fields.description.stringValue}
                                    />
                                })
                            }
                        </div>
                    </>
                }
                {
                    chips &&
                    <div className="quick-replies-container">
                        {
                            chips.map((chip, i) => {
                                return <QuickReply
                                    key={i}
                                    text={chip.value}
                                    payload={chip.value ? chip.value : null}
                                    link={chip.link !== "" ? chip.link : null}
                                    reactLink={chip.reactLink ? chip.reactLink : null}
                                    handleQuickReply={handleQuickReply}
                                />
                            })
                        }
                    </div>
                }

            </div>
            </div> 
            : null
             
        }
        {
            speaker === 'user' ?
            <div className="wrapper">
              <div className="message-container-user">
                  <div className="message-content">
                      <p>{text}</p>
                  </div>
              </div>
            </div>
            :null
        }
        </>
)

export default Message;
