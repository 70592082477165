import React from 'react';

import './quick-reply.styles.scss';

const QuickReply = ({text, payload, link, reactLink, handleQuickReply}) => (
    <div className="quick-reply">
        {
            link === null ?
            <button onClick={() => handleQuickReply(text, payload)}>{text}</button>
            : 
            <a href={link} target="_blank" rel="noopener noreferrer"><button className="link-button">{text} {link.includes("vic.gov.au") ? <svg width="14px" height="14px" style={{marginLeft: "10px"}} fill="#0052c2" xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 13 12" id="rpl_icon_arrow_right_primary"><path fillRule="evenodd" clipRule="evenodd" d="M11.74 5.326l-4.546-5A1.003 1.003 0 005.781.259a1.001 1.001 0 00-.066 1.413l3.024 3.327H1a1 1 0 100 2h7.739l-3.024 3.327a1.001 1.001 0 00.739 1.673 1 1 0 00.74-.327l4.546-5a1 1 0 000-1.346z" fill="inherit"></path></svg> : <svg width="14" height="14" style={{marginLeft: "10px"}} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.54V12.602C14 13.3 13.3 14 12.46 14H1.54C0.7 14 0 13.3 0 12.46V1.54C0 0.7 0.7 0 1.54 0H12.602C13.3 0 14 0.7 14 1.54ZM11.2 1.96H7.7C7.14 1.96 6.719 2.8 7.281 3.219L8.261 4.199L2.101 10.359C2.03292 10.437 1.9954 10.537 1.9954 10.6405C1.9954 10.744 2.03292 10.844 2.101 10.922L3.078 11.898C3.218 12.039 3.5 12.039 3.641 11.898L9.801 5.738L10.781 6.718C11.199 7.281 12.039 6.859 12.039 6.301V2.8C12.039 2.378 11.621 1.96 11.199 1.96H11.2Z" fill="#0052C2"/></svg>}  </button></a> 
        }
    </div>
)

export default QuickReply;